<template>
  <div class="companies-map">
    <ve-bmap
      ref="mapRef"
      height="100%"
      :settings="chartSettings"
      :series="chartSeries"
      :tooltip="chartTooltip"
      :events="chartEvents"
    ></ve-bmap>
    <div class="chain-block-box" v-show="companyList.length > 0">
      Hash值区块链验证:
      <span v-if="!hashFlag" class="hash-value" @click="verifyHash">
        <i class="el-icon-thumb"></i>
        {{ hashVal }}
      </span>
      <span v-else class="hash-result" @click="verifyHash">数据无篡改</span>
    </div>
    <div class="company-list-box">
      <div style="text-align: right">
        <el-button
          v-if="companyListFlag"
          size="small"
          type="warning"
          icon="el-icon-minus"
          @click="companyListFlag = false"
          >收起</el-button
        >
        <el-button
          v-else
          size="small"
          type="primary"
          icon="el-icon-office-building"
          @click="companyListFlag = true"
          >展开</el-button
        >
      </div>
      <el-collapse-transition>
        <div v-show="companyListFlag" class="company-list">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-input
                style="width: 100%"
                v-model="listQuery.keyword"
                size="mini"
                placeholder="输入关键字搜索"
                @input="handleInput"
              >
                <template slot="prepend">生产经营者名称</template>
              </el-input>
            </div>
            <div>
              <el-table
                style="background: transparent"
                border
                fit
                :data="companyList"
                :show-header="false"
                highlight-current-row
                :max-height="500"
                @row-click="handleRowClick"
              >
                <el-table-column
                  prop="companyName"
                  align="center"
                ></el-table-column>
              </el-table>
              <el-pagination
                small
                background
                hide-on-single-page
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="listQuery.page"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="parseInt(listQuery.limit)"
                layout=" prev, pager, next"
                :total="total"
              ></el-pagination>
            </div>
          </el-card>
        </div>
      </el-collapse-transition>
    </div>
    <div style="display: none">
      <div ref="customInfowindow">
        <h4 style="margin: 0 0 5px 0; padding: 0.2em 0">
          名称:{{ companyInfo.company_name }}
        </h4>
        <div style="margin-top: 5px">
          <i class="el-icon-location"></i>
          <span>地址:{{ companyInfo.address }}</span>
        </div>
        <div style="margin-top: 10px">
          <el-row :gutter="20">
            <el-col :span="12"> </el-col>
            <el-col :span="12"> </el-col>
          </el-row>
        </div>
        <el-button
          style="margin-top: 10px"
          type="primary"
          @click="handleDailyClick(0)"
          size="small"
          >委托列表</el-button
        >
        <el-button
          style="margin-top: 10px"
          type="primary"
          size="small"
          @click="handleDailyClick(1)"
          >受托列表</el-button
        >
      </div>
    </div>
    <el-dialog
      :title="entrustQuery.index ? '受托列表' : '委托列表'"
      :visible.sync="dailyVisible"
      width="1500px"
      custom-class="daily-box"
    >
      <div class="df" style="background-color: #eee">
        <div
          style="
            flex: 1;
            background-color: #fff;
            height: 800px;
            border-radius: 10px;
            padding: 20px;
            transition: transform 250ms, opacity 400ms;
          "
        >
          <div class="df" style="flex-direction: column; height: 100%">
            <div style="width: 600px">
              <el-form
                :inline="true"
                :model="entrustQuery"
                class="demo-form-inline"
              >
                <el-col :span="12" class="mr10">
                  <el-form-item size="medium">
                    <el-input
                      placeholder="请输入关键词查询"
                      v-model="entrustQuery.keyword"
                      class="input-with-select"
                      clearable
                      @clear="entrustSearch"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3" class="tl mr10">
                  <el-form-item size="medium">
                    <el-button
                      type="primary"
                      @click="entrustSearch"
                      class="general_bgc general_border cfff"
                      >查询</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-form>
            </div>
            <div
              style="flex: 1; height: 0; overflow-y: scroll"
              class="daily-detail"
            >
              <el-table :data="entrustList" style="width: 100%">
                <el-table-column width="55" type="index" label="序号">
                </el-table-column>
                <el-table-column
                  prop="companyName"
                  v-if="entrustQuery.index"
                  label="委托企业"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="entrustedCompanyName"
                  velse
                  label="受托企业"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="statusText"
                  velse
                  label="状态"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="createdTime"
                  label="创建时间"
                  width="100"
                >
                  <template slot-scope="scope">
                    {{ scope.row.createdTime.replace("T", " ") }}
                  </template>
                </el-table-column>
                <el-table-column prop="isDefault" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      class="disib"
                      size="mini"
                      @click="handleDetail(scope.row)"
                      >详情</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="df" style="justify-content: flex-end; padding: 20px 0">
              <el-pagination
                background
                :page-size="entrustQuery.limit"
                layout="total,prev, pager, next"
                :total="entrustTotal"
                :current-page.sync="entrustQuery.page"
                @current-change="entrustPageChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div
          v-if="showNext"
          v-loading="nextLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="
            flex: 0 0 800px;
            background-color: #fff;
            height: 800px;
            margin-left: 10px;
            border-radius: 10px;
          "
        >
          <div
            class="df aic jcc"
            style="height: 100%; width: 100%"
            v-if="!entrustDetail"
          >
            <el-empty description="暂未填写"></el-empty>
          </div>
          <div
            v-else
            class="daily-detail"
            style="padding: 20px; height: 100%; overflow-y: scroll"
          >
            <el-card class="box-card" style="margin-bottom: 20px">
              <div slot="header">
                <div class="df">基本信息</div>
              </div>
              <div>
                <el-form ref="form" :model="entrustDetail" label-width="80px">
                  <el-form-item
                    :label="entrustQuery.index ? '委托企业' : '受托企业'"
                  >
                    <el-input
                      style="width: 80%"
                      v-model="entrustDetail.companyName"
                      v-if="entrustQuery.index"
                      readonly
                    ></el-input>
                    <el-input
                      style="width: 80%"
                      v-model="entrustDetail.entrustedCompanyName"
                      v-else
                      readonly
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="委托业务">
                    <el-input
                      style="width: 80%"
                      v-model="entrustDetail.entrustTitle"
                      readonly
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="业务描述">
                    <el-input
                      style="width: 80%"
                      v-model="entrustDetail.entrustContent"
                      type="textarea"
                      readonly
                      autosize
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
            <el-card
              class="box-card"
              style="margin-bottom: 20px"
              v-if="entrustDetail.mode !== null"
            >
              <div slot="header">
                <div class="df">发货信息</div>
              </div>
              <el-form ref="form" :model="entrustDetail" label-width="80px">
                <el-form-item label="发货类型">
                  <el-input
                    style="width: 80%"
                    v-model="['自主货运', '快递寄送'][entrustDetail.mode]"
                    readonly
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="发货实景"
                  v-if="
                    entrustDetail.imgList &&
                    JSON.parse(entrustDetail.imgList).length
                  "
                >
                  <div
                    style="
                      width: 90%;
                      padding-left: 70px;
                      justify-content: flex-start;
                      flex-wrap: wrap;
                    "
                    class="df aic"
                  >
                    <div
                      style="
                        width: 100px;
                        height: 100px;
                        margin: 10px;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-for="(item, index) in JSON.parse(entrustDetail.imgList)"
                      :key="index"
                    >
                      <img
                        :src="item"
                        style="
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                          cursor: pointer;
                        "
                        alt=""
                        @click="handlePreviewImage(item)"
                      />
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="运输车辆" v-if="!entrustDetail.mode">
                  <el-input
                    style="width: 80%"
                    v-model="entrustDetail.carNumber"
                    readonly
                  ></el-input>
                </el-form-item>
                <el-form-item label="挂车牌号" v-if="!entrustDetail.mode">
                  <el-input
                    style="width: 80%"
                    placeholder="未填写"
                    v-model="entrustDetail.guaNumber"
                    readonly
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input
                    style="width: 80%"
                    v-model="entrustDetail.remark"
                    placeholder="未填写"
                    type="textarea"
                    readonly
                    autosize
                  ></el-input>
                </el-form-item>
              </el-form>
            </el-card>
            <el-card class="box-card" style="margin-bottom: 20px">
              <div slot="header">
                <div class="df">相关货品</div>
              </div>
              <div
                class="df aic"
                style="
                  margin: 10px;
                  padding: 20px;
                  margin: 10px;
                  border-bottom: 1px solid #eee;
                "
                v-for="(item, index) in entrustDetail.infoList"
                :key="index"
              >
                <div
                  style="
                    width: 100px;
                    height: 100px;
                    border-radius: 10px;
                    overflow: hidden;
                  "
                >
                  <img
                    :src="JSON.parse(item.urls)[0]"
                    style="width: 100%; height: 100%; object-fit: cover"
                    alt=""
                  />
                </div>
                <div style="flex: 1; width: 0" class="goodsInfo">
                  <div class="df aic" style="line-height: 24px">
                    <div
                      class="df"
                      style="
                        justify-content: flex-end;
                        width: 80px;
                        margin-right: 20px;
                      "
                    >
                      商品名
                    </div>
                    <div class="df" style="justify-content: flex-start">
                      {{ item.name }}
                    </div>
                  </div>
                  <div class="df aic" style="line-height: 24px">
                    <div
                      class="df"
                      style="
                        justify-content: flex-end;
                        width: 80px;
                        margin-right: 20px;
                      "
                    >
                      商品编码
                    </div>
                    <div class="df" style="justify-content: flex-start">
                      {{ item.gtin }}
                    </div>
                  </div>
                  <div class="df aic" style="line-height: 24px">
                    <div
                      class="df"
                      style="
                        justify-content: flex-end;
                        width: 80px;
                        margin-right: 20px;
                      "
                    >
                      规格
                    </div>
                    <div class="df" style="justify-content: flex-start">
                      {{ item.specification ? item.specification : "未填写" }}
                    </div>
                  </div>
                  <div class="df aic" style="line-height: 24px">
                    <div
                      class="df"
                      style="
                        justify-content: flex-end;
                        width: 80px;
                        margin-right: 20px;
                      "
                    >
                      商品类型
                    </div>
                    <div class="df" style="justify-content: flex-start">
                      {{ item.categoryName ? item.categoryName : "未填写" }}
                    </div>
                  </div>
                  <div class="df aic" style="line-height: 24px">
                    <div
                      class="df"
                      style="
                        justify-content: flex-end;
                        width: 80px;
                        margin-right: 20px;
                      "
                    >
                      批次信息
                    </div>
                    <div
                      class="df"
                      style="justify-content: flex-start; flex-wrap: wrap"
                    >
                      <el-tag
                        v-for="(tItem, tIndex) in item.batch"
                        :key="tIndex"
                        style="margin: 10px 0; margin-right: 10px"
                        >{{ tItem.batchNumber
                        }}<span style="margin: 0 5px">|</span
                        >{{ tItem.goodsWeight }}{{ tItem.unitName }}</el-tag
                      >
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            <el-card
              class="box-card"
              style="margin-bottom: 20px"
              v-if="entrustDetail.otherInfo && entrustDetail.otherInfo !== '[]'"
            >
              <div slot="header">
                <div class="df">其他信息</div>
              </div>
              <div style="padding: 0 10px; background-color: #f8f8f8">
                <div
                  style="background-color: #fff"
                  v-for="(oItem, oIndex) in entrustDetail.otherInfo"
                  :key="oIndex"
                >
                  <div style="height: 10px; background-color: #f8f8f8"></div>
                  <div style="padding:5px;10px;border-bottom: 1px solid #eee;">
                    {{ oItem.title }}
                  </div>
                  <div v-if="oItem.type === 0" style="padding: 10px">
                    <el-input
                      v-model="oItem.data"
                      type="textarea"
                      readonly
                      autosize
                    ></el-input>
                  </div>
                  <div
                    v-if="oItem.type === 1"
                    class="df aic"
                    style="flex-wrap: wrap"
                  >
                    <div
                      style="
                        width: 100px;
                        height: 100px;
                        margin: 10px;
                        border-radius: 10px;
                        overflow: hidden;
                      "
                      v-for="(iItem, iIndex) in JSON.parse(oItem.data)"
                      :key="iIndex"
                    >
                      <img
                        :src="iItem"
                        style="
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                          cursor: pointer;
                        "
                        alt=""
                        @click="handlePreviewImage(iItem)"
                      />
                    </div>
                  </div>
                  <div
                    v-if="oItem.type === 2"
                    class="df aic"
                    style="flex-wrap: wrap"
                  >
                    <div
                      style="
                        width: 220px;
                        height: 140px;
                        margin: 10px;
                        border-radius: 10px;
                        overflow: hidden;
                        background-color: #000;
                      "
                      v-for="(vItem, vIndex) in JSON.parse(oItem.data)"
                      :key="vIndex"
                    >
                      <video
                        :src="vItem"
                        style="width: 100%; height: 100%"
                        controls
                      ></video>
                    </div>
                  </div>
                </div>
                <div style="height: 10px; background-color: #f8f8f8"></div>
              </div>
            </el-card>
            <el-card
              v-if="entrustDetail.recordList && entrustDetail.recordList.length"
              class="box-card"
              style="margin-bottom: 20px"
            >
              <div slot="header">
                <div class="df">处理记录</div>
              </div>
              <el-steps
                direction="vertical"
                :active="entrustDetail.recordList.length"
              >
                <el-step
                  :title="rItem.name"
                  v-for="(rItem, rIndex) in entrustDetail.recordList"
                  :key="rIndex"
                >
                  <template #description>
                    <el-form
                      ref="form"
                      :model="entrustDetail"
                      label-width="80px"
                    >
                      <el-form-item label="处理时间">
                        <span style="color: #000">
                          {{ rItem.createdTime.replace("T", " ") }}
                        </span>
                      </el-form-item>
                      <el-form-item
                        label="处理实景"
                        v-if="rItem.imgList && JSON.parse(rItem.imgList).length"
                      >
                        <div
                          style="
                            width: 90%;
                            justify-content: flex-start;
                            flex-wrap: wrap;
                          "
                          class="df aic"
                        >
                          <div
                            style="
                              width: 100px;
                              height: 100px;
                              margin: 10px;
                              border-radius: 10px;
                              overflow: hidden;
                            "
                            v-for="(item, index) in JSON.parse(rItem.imgList)"
                            :key="index"
                          >
                            <img
                              :src="item.url"
                              style="
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                cursor: pointer;
                              "
                              alt=""
                              @click="handlePreviewImage(item.url)"
                            />
                          </div>
                        </div>
                      </el-form-item>
                      <el-form-item
                        label="处理实录"
                        v-if="
                          rItem.videoList && JSON.parse(rItem.videoList).length
                        "
                      >
                        <div
                          style="
                            width: 90%;
                            justify-content: flex-start;
                            flex-wrap: wrap;
                          "
                          class="df aic"
                        >
                          <div
                            style="
                              width: 220px;
                              height: 140px;
                              margin: 10px;
                              border-radius: 10px;
                              overflow: hidden;
                              background-color: #000;
                            "
                            v-for="(vItem, vIndex) in JSON.parse(
                              rItem.videoList
                            )"
                            :key="vIndex"
                          >
                            <video
                              :src="vItem.url"
                              style="width: 100%; height: 100%"
                              controls
                            ></video>
                          </div>
                        </div>
                      </el-form-item>
                      <el-form-item label="处理记录" v-if="rItem.remark">
                        <el-input
                          style="width: 80%"
                          v-model="rItem.remark"
                          placeholder="未填写"
                          type="textarea"
                          readonly
                          autosize
                        ></el-input>
                      </el-form-item>

                      <el-form-item>
                        <template #label>
                          <div style="line-height: 16px">采集点</div>
                          <div style="line-height: 16px">GPS</div>
                        </template>
                        <span style="color: #000">
                          {{ rItem.longitude }},{{ rItem.latitude }}
                        </span>
                      </el-form-item>
                    </el-form>
                  </template>
                </el-step>
              </el-steps>
            </el-card>
            <el-card
              v-if="entrustDetail.finishedInfo"
              class="box-card"
              style="margin-bottom: 20px"
            >
              <div slot="header">
                <div class="df">
                  完成报告 - {{ entrustDetail.finishedInfo.title }}
                </div>
              </div>
              <div>
                <el-form
                  ref="form"
                  :model="entrustDetail.finishedInfo"
                  label-width="80px"
                >
                  <el-form-item
                    label="相关图片"
                    v-if="
                      entrustDetail.finishedInfo.imgUrls &&
                      JSON.parse(entrustDetail.finishedInfo.imgUrls).length
                    "
                  >
                    <div
                      style="
                        width: 90%;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                      "
                      class="df aic"
                    >
                      <div
                        style="
                          width: 100px;
                          height: 100px;
                          margin: 10px;
                          border-radius: 10px;
                          overflow: hidden;
                        "
                        v-for="(item, index) in JSON.parse(
                          entrustDetail.finishedInfo.imgUrls
                        )"
                        :key="index"
                      >
                        <img
                          :src="item"
                          style="
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            cursor: pointer;
                          "
                          alt=""
                          @click="handlePreviewImage(item)"
                        />
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item
                    label="相关文件"
                    v-if="
                      entrustDetail.finishedInfo.fileUrls &&
                      JSON.parse(entrustDetail.finishedInfo.fileUrls).length
                    "
                  >
                    <div
                      v-for="(item, index) in JSON.parse(
                        entrustDetail.finishedInfo.fileUrls
                      )"
                      @click="open(item.url)"
                      class="df aic"
                      :key="index"
                      style="cursor: pointer;margin-bottom:10px"
                    >
                      <i
                        class="el-icon-document"
                        style="font-size: 30px; color: #0081ff"
                      ></i>
                      <span>{{ item.fileName }}</span>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
            <!-- </el-collapse> -->
          </div>
        </div>
      </div>
    </el-dialog>

    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="[url]"
    />
    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer1"
      :on-close="closeViewer1"
      :url-list="url1"
    />
  </div>
</template>

<script>
// import axios from "@/util/api.js";
import axios from "../../../util/api";
import api from "../../../util/extra-api";
import { diffTime, createHash } from "@/util/util";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { mapConfig } from "./custom_map_config.js";
import { local } from "../../../util/util";
// import DailyVideo from "./components/dailyVideo";
import dayjs from "dayjs";
import { pickerOptions } from "../sensor_screen/optionData";

export default {
  components: {
    ElImageViewer,
    // DailyVideo,
  },
  data() {
    this.chartSettings = {
      key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
      bmap: {
        center: [120, 30],
        zoom: 5,
        roam: true,
        enableMapClick: false,
        mapStyleV2: { styleJson: mapConfig },
      },
    };
    this.chartTooltip = {
      show: true,
      formatter: function (params, ticket, callback) {
        return `${params.marker}${params.value[2]}`;
      },
    };
    let _this = this;
    this.chartEvents = {
      click: (v) => {
        //console.log("v: ", v);
        _this.$nextTick((_) => {
          _this.companyInfo.company_name = v.value[2];
          _this.companyInfo.address = v.value[4];
          _this.companyInfo.company_type_id = v.value[5];
          _this.companyId = v.value[3];
          var echarts = this.$refs.mapRef.echarts;
          var map = echarts.getModel().getComponent("bmap").getBMap();
          var point = new window.BMap.Point(v.value[0], v.value[1]);
          var infoWindow = new window.BMap.InfoWindow(
            _this.$refs.customInfowindow
          );
          // if (this.clickTimes == 0) {
          //   map.centerAndZoom(point, 14);
          // }
          map.setCenter(point);
          map.openInfoWindow(infoWindow, point);
          this.clickTimes++;
        });
      },
      finished: (_) => {
        // _this.getGeo();
      },
    };
    this.chartSettingsEvaluate = {
      roseType: "radius",
    };
    this.chartExtendEvaluate = {
      legend: {
        textStyle: {
          color: "#ffffff", //字体颜色
        },
      },
    };
    return {
      nextLoading: false,
      entrustDetail: null,
      entrustList: [],
      entrustTotal: 0,
      entrustQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        companyId: null,
        index: 1,
      },
      camId: null,
      timeValue: "",
      imageSrc: "",
      picLoading: false,
      picsVisible: false,
      companyId: null,
      dataType: null,
      barcode: "",
      companyList: [],
      chartSeries: [],
      srlistPage: 1,
      rrlistPage: 1,
      recordData: {},
      companyInfo: {
        company_name: "",
        address: "",
        company_type_id: "",
      },
      storageData: [],
      unitData: [],
      filterForm: {
        flag: 0,
        goodsName: "",
        batchNumber: "",
        gtin: "",
      },
      filterRecordForm: {
        goodsName: "",
        gtin: "",
        batchNumber: "",
        carNumber: "",
        goodsType: 5,
      },
      recordDialogVisible: false,
      supDialogVisible: false,
      storageDialogVisible: false,
      unitDialogVisible: false,
      picDialogVisible: false,
      attachList: [],
      reportList: [],
      clickTimes: 0,
      level: "",
      reason: "",
      file: "",
      search: "",
      actionPath:
        process.env.VUE_APP_BASE_API + "/api/v1/department/sup_upload",
      baseUrl: process.env.VUE_APP_BASE_API,
      companyListFlag: true,
      hashFlag: false,
      hashVal: createHash(30),
      // listQuery: {
      //   pager_offset: "0",
      //   pager_openset: "100",
      //   department_type: "",
      // },
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      total: null,
      listLoading1: false,
      list1: [],
      listQuery1: {
        opt: "1",
        pager_offset: "0",
        count: "20",
        company_id: "",
      },
      listTotal1: 0,
      table1DialogVisible: false,
      table2DialogVisible: false,
      table3DialogVisible: false,
      table4DialogVisible: false,
      camDialogVisible: false,
      listLoading2: false,
      list2: [],
      camListQuery: {
        company_id: "",
        page: 1,
        count: 6,
      },
      camList: [],
      listTotal2: 0,
      plantingGoodsList: [],
      plantingGoodsListTotal: 0,
      goodsDialogVisible: false,
      farmRecordList: [],
      farmRecordListTotal: 0,
      farmDialogVisible: false,
      flowRecordList: [],
      flowRecordListTotal: 0,
      flowDialogVisible: false,
      salesGoodsRecordList: [],
      salesGoodsRecordListTotal: 0,
      salesGoodsDialogVisible: false,
      url: "",
      url1: [],
      showViewer: false,
      showViewer1: false,
      pickerOptionsData: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      time: [],
      showHACCP: false,
      showHACCPDetail: false,
      haccpQuery1: {
        page: 1,
        limit: 10,
        keyword: "",
        companyId: null,
      },
      haccpDetailList: [],
      showEvaluate: false,
      evaluateList: [],
      showEvaluateChart: true,
      tableKey: 0, // 表格的键
      evaluateTableList: null, // 表格的数据
      evaluateTotal: null, // 表格的数据数量
      listLoading: true, // 表格加载状态
      tableHeight: 450,
      evaluateListQuery: {
        companyId: null,
        page: 1, // 表格的页面值
        limit: 20, // 表格的单页数量
      },
      imgList: [],
      imgDialogWidth: null,
      replyDialogVisible: false,
      haccpTotal: 0,
      haccpDetailTotal: 0,
      haccpCurrentPage: 1,
      haccpDetailCurrentPage: 1,
      HACCPDetailId: null,
      haccpType: 0,
      dailyVisible: false,
      dailyList: [],
      dailyDetail: [],
      dailyDetailTotal: 0,
      dailyDetailQuery: {
        batchId: null,
        keyword: "",
        page: 1,
        limit: 50,
      },
      dailyQuery: {
        companyId: null,
        keyword: "",
        page: 1,
        limit: 10,
      },
      dailyTotal: 0,
      dailyRecordQuery: {
        swId: null,
        keyword: "",
        page: 1,
        limit: 10,
        companyId: null,
      },
      dailyRecordTotal: 0,
      dailyRecordList: [],
      showRecord: false,
      showNext: false,
      showView: false,
      dailyViewQuery: {
        batchId: null,
        keyword: "",
        page: 1,
        limit: 13,
      },
      dailyViewTotal: 0,
      dailyViewList: [],
      activeName: "",
      dailyComment: [],
      dailyCommentForm: {
        txtInfo: "",
        imgInfo: [],
        videoInfo: [],
      },
      oldComment: null,
      videoFeedbackVisible: false,
      receiveFeedbackVisible: false,
      sendFeedbackVisible: false,
      notificationId: null,
      sensorVisible: false,
      sensorQuery: {
        page: 1,
        limit: 10,
        companyId: null,
      },
      sensorList: [],
      sensorTotal: 0,
      showHistory: false,
      showFeedback: false,
      lineChartFlag: false,
      dateRange: "",
      pickerOptions1: pickerOptions,
      gridData: [],
      hisQuery: {
        id: null,
        page: 1,
        companyId: 2582225,
        limit: 50,
        startTime: "",
        endTime: "",
      },
      sensorLoading: false,
      lineChartData: {},
      pageFlag: false,
      screenshotUrl: "",
    };
  },
  computed: {},
  created() {},

  filters: {
    timeFormat(timeString) {
      return diffTime(timeString);
    },
  },
  mounted() {
    this.userData = local.get("user");
    this.initMap();
  },
  watch: {
    "lineChartData.rows"(v) {
      this.$nextTick((_) => {
        this.$refs[`lineChart`].echarts.resize();
      });
    },
    showEvaluate(v) {
      if (!v) this.showEvaluateChart = true;
    },
    showHACCP(v) {
      if (!v) {
        this.haccpQuery1.page = 1;
        this.haccpQuery1.keyword = "";
        this.haccpCurrentPage = 1;
      }
    },
    showHACCPDetail(v) {
      if (!v) {
        this.haccpQuery2.page = 1;
        this.haccpDetailCurrentPage = 1;
      }
    },
  },
  methods: {
    getRecordList(entrustId) {
      api.get("/v1/pc/entrust/recordList", { entrustId }).then((res) => {
        if (res.code === 200) {
          this.$set(this.entrustDetail, "recordList", res.data);
        }
      });
    },
    getEntrustList() {
      api
        .get("/v1/pc/entrust/list", {
          ...this.entrustQuery,
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.entrustList = res.data.map((v) => {
              if (v.statusStr && v.status !== 7) {
                v.statusText = v.statusStr;
              } else {
                if (this.entrustQuery.index === 0) {
                  v.statusText = [
                    "委托中-未读",
                    "委托中-已读",
                    "已同意委托",
                    "委托已确认",
                    "待收货",
                    "委托处理中",
                    "已撤回",
                    "已完成",
                    "已拒绝",
                  ][v.status];
                } else {
                  v.statusText = [
                    "委托中-未读",
                    "委托中-已读",
                    "委托待推进",
                    "委托已确认",
                    "待收货",
                    "委托处理中",
                    "已撤回",
                    "已完成",
                    "已拒绝",
                  ][v.status];
                }
              }
              return v;
            });
            this.entrustTotal = res.total;
          }
        });
    },
    handleScreenshot(url, data) {
      if (url) {
        this.screenshotUrl = url;
      }
      data.isFeedback = false;
      this.videoFeedbackVisible = true;
    },
    open(url) {
      window.open(url);
    },
    openVideo(data) {
      // this.videoFeedbackVisible = true;
      this.screenshotUrl = "";
      this.camId = data.id;
      data.isFeedback = true;
    },
    onChangeChart() {
      this.lineChartFlag = !this.lineChartFlag;

      if (this.lineChartFlag) {
        this.lineChartLoading = true;
        api
          .get("/v1/wx/device/sensorHisData", this.hisQuery)
          .then((response) => {
            const data = response.data.data;
            this.lineChartData = {
              columns: ["timeDate", "value"],
              rows: data,
            };
            this.lineChartLoading = false;
          });
      }
    },
    openHistory(data) {
      this.hisQuery.id = data.id;
      this.getHistoryData();
      this.showFeedback = false;
      this.showHistory = true;
    },
    openFeedback(data) {
      if (!this.showFeedback) {
        this.notificationId = data.id;
        this.showHistory = false;
        this.showFeedback = true;
      } else {
        this.notificationId = data.id;
        this.showFeedback = false;
        this.$nextTick(() => {
          this.showFeedback = true;
        });
      }
    },
    handleCurrentChangeDialog(val) {
      this.hisQuery.page = val;
      this.sensorLoading = true;
      api.get("/v1/wx/device/sensorHisData", this.hisQuery).then((response) => {
        const data = response.data;
        this.gridData = data.data.map((m) => {
          return {
            data: m.value + m.unit,
            time: m.timeDate,
          };
        });
        this.dialogTotal = data.total;
        this.sensorLoading = false;
      });
    },
    getHistoryData() {
      this.sensorLoading = true;
      api.get("/v1/wx/device/sensorHisData", this.hisQuery).then((response) => {
        const data = response.data;
        this.gridData = data.data.map((m) => {
          return {
            data: m.value + m.unit,
            time: m.timeDate,
          };
        });
        this.dialogTotal = data.total;
        this.sensorLoading = false;
      });
    },
    onSearch() {
      if (this.dateRange && this.dateRange.length > 0) {
        this.sensorLoading = true;
        this.hisQuery.page = 1;
        this.pageFlag = true;
        this.buttonFlag = true;
        this.hisQuery.startTime = this.dateRange[0];
        this.hisQuery.endTime = this.dateRange[1];
        api
          .get("/v1/wx/device/sensorHisData", this.hisQuery)
          .then((response) => {
            const data = response.data;
            this.gridData = data.data.map((m) => {
              return {
                data: m.value + m.unit,
                time: m.timeDate,
              };
            });
            this.dialogTotal = data.total;
            this.sensorLoading = false;
          });
      } else {
        this.$message({
          message: "请先选择时间区间",
          type: "warning",
        });
      }
    },
    getSensorList() {
      api.get("/v1/pc/area/monitor", this.sensorQuery).then((res) => {
        if (res.code === 200) {
          this.sensorList = res.data;
          this.sensorTotal = res.total;
        }
      });
    },
    handleEditCom(item, data) {
      if (
        JSON.parse(data.imgInfo).length ||
        JSON.parse(data.videoInfo).length ||
        data.txtInfo
      ) {
        let parmas = {
          ccpId: data.ccpId,
          id: data.id,
          companyId: this.dailyQuery.companyId,
        };
        if (data.txtInfo) {
          parmas.txtInfo = data.txtInfo;
        }
        if (JSON.parse(data.imgInfo).length) {
          parmas.imgInfo = data.imgInfo;
        }
        if (JSON.parse(data.videoInfo).length) {
          parmas.videoInfo = data.videoInfo;
        }
        api.post("/v1/pc/sw/updateRemarkOn", parmas).then((res) => {
          if (item.query.page !== 1 && item.query.length === 1) {
            item.query.page--;
            item.isOpen = false;
            this.handleCollapseChange(item);
          } else {
            item.isOpen = false;
            this.handleCollapseChange(item);
          }
          this.oldComment = null;
        });
      }
    },
    editCom(data) {
      if (!data.isEdit) {
        this.oldComment = data;
        data.isEdit = true;
      } else {
      }
    },
    delCorrect(item, data) {
      console.log(item, data);
      api
        .post("/v1/pc/sw/removeRemarkOn", {
          id: data.id,
          companyId: this.dailyQuery.companyId,
        })
        .then((res) => {
          if (item.query.page !== 1 && item.query.length === 1) {
            item.query.page--;
            item.isOpen = false;
            this.handleCollapseChange(item);
          } else {
            item.isOpen = false;
            this.handleCollapseChange(item);
          }
        });
    },
    createCorrect(data) {
      console.log(data);
      api
        .post("/v1/pc/sw/remarkOn", {
          ccpId: data.id,
          txtInfo: this.dailyCommentForm.txtInfo,
          videoInfo: JSON.stringify(this.dailyCommentForm.videoInfo),
          imgInfo: JSON.stringify(this.dailyCommentForm.imgInfo),
        })
        .then((res) => {
          if (res.code === 200) {
            this.dailyCommentForm.txtInfo = "";
            this.dailyCommentForm.imgInfo = [];
            this.dailyCommentForm.videoInfo = [];
            api.get("/v1/pc/sw/remarkOnList", data.query).then((res) => {
              if (res.code === 200) {
                this.$set(data, "dailyComment", res.data);
                this.$set(data, "total", res.total);
              }
            });
          }
        });
    },
    addImg(params, data) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", 1117);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          let imgs = [...this.dailyCommentForm.imgInfo];
          imgs.push({
            name: resp.name,
            url: resp.url,
          });
          // console.log(data.form);
          this.$set(this.dailyCommentForm, "imgInfo", imgs);
          // console.log(data);
        }
      });
    },
    addComImg(params, data) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", 1117);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          let imgs = [...JSON.parse(data.imgInfo)];
          imgs.push({
            name: resp.name,
            url: resp.url,
          });
          // console.log(data.form);
          this.$set(data, "imgInfo", JSON.stringify(imgs));
          // console.log(data);
        }
      });
    },
    addComVideo(params, data) {
      const file = params.file,
        fileType = file.type,
        isVidoe = fileType.includes("video");
      // console.log(file, isVidoe);
      if (!isVidoe) {
        this.$message.error("只能上传视频格式!");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", 1117);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("视频上传失败，请重新上传");
        } else {
          let videos = [...JSON.parse(data.videoInfo)];

          videos.push({
            name: resp.name,
            url: resp.url,
          });
          this.$set(data, "videoInfo", JSON.stringify(videos));
        }
      });
    },
    handleImgRemoveJSON(data, i) {
      this.$set(
        data,
        "imgInfo",
        JSON.stringify(
          JSON.parse(data.imgInfo).filter((item, index) => index !== i)
        )
      );
    },
    handlePreviewVideoJSON(i) {
      console.log(this.$refs["video" + i], "视频预览");
      let videoEle = this.$refs["video" + i];
      videoEle.play();
      this.$refs["video" + i].play();
    },
    handleVideoRemoveJSON(data, i) {
      this.$set(
        data,
        "videoInfo",
        JSON.stringify(
          JSON.parse(data.videoInfo).filter((item, index) => index !== i)
        )
      );
    },
    handleImgRemove(data, i) {
      console.log(data, i, "图片");
      this.$set(
        this.dailyCommentForm,
        "imgInfo",
        this.dailyCommentForm.imgInfo.filter((item, index) => index !== i)
      );
    },
    addVideo(params, data) {
      const file = params.file,
        fileType = file.type,
        isVidoe = fileType.includes("video");
      // console.log(file, isVidoe);
      if (!isVidoe) {
        this.$message.error("只能上传视频格式!");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", 1117);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("视频上传失败，请重新上传");
        } else {
          // console.log(resp.data);
          let videos = [...this.dailyCommentForm.videoInfo];

          videos.push({
            name: resp.name,
            url: resp.url,
          });
          this.$set(this.dailyCommentForm, "videoInfo", videos);
        }
      });
    },
    handleCollapseChange(item) {
      // console.log(item.isOpen);
      if (item.isOpen) {
        this.$set(item, "isOpen", false);
      } else {
        this.dailyCommentForm = {
          txtInfo: "",
          imgInfo: [],
          videoInfo: [],
        };
        api.get("/v1/pc/sw/remarkOnList", item.query).then((res) => {
          if (res.code === 200) {
            this.$set(
              item,
              "dailyComment",
              res.data.map((v) => {
                v.isEdit = false;
                return v;
              })
            );
            this.$set(item, "total", res.total);
            this.$set(item, "isOpen", true);
          }
        });
      }
    },
    handleDetail(row) {
      this.showNext = true;
      this.nextLoading = true;
      this.getEntrustDetail(row.id);
    },
    getEntrustDetail(id) {
      api.get("/v1/pc/entrust/detail", { id }).then((res) => {
        if (res.code === 200) {
          this.entrustDetail = res.data;
          let arr = [...new Set(res.data.infoList.map((v) => v.goodsId))];
          let goodsList = [];
          if (this.entrustDetail.finishedInfo) {
            this.entrustDetail.finishedInfo = JSON.parse(
              this.entrustDetail.finishedInfo
            );
          }
          arr.forEach((v) => {
            let list = res.data.infoList.filter((item) => item.goodsId === v);
            let gtinInfo = JSON.parse(list[0].gtinInfo);
            let data = {
              name: gtinInfo.goodsName,
              specification: gtinInfo.spec,
              categoryName: gtinInfo.goodsType,
              urls: JSON.stringify(gtinInfo.imgList),
              goodsId: v,
              gtin: gtinInfo.code,
              batch: list.map((item, index) => {
                return {
                  batchNumber: item.batchNumber,
                  goodsId: item.goodsId,
                  goodsName: item.goodsName,
                  gtinInfo: item.gtinInfo,
                  goodsWeight: String(item.num),
                  unitName: item.unit,
                  key: list[0].goodsId + "I" + index,
                };
              }),
            };
            goodsList.push(data);
          });
          this.entrustDetail.infoList = goodsList;

          if (
            this.entrustDetail.otherInfo &&
            this.entrustDetail.otherInfo !== "[]"
          ) {
            this.entrustDetail.otherInfo = JSON.parse(
              this.entrustDetail.otherInfo
            );
          } else {
            this.entrustDetail.otherInfo = null;
          }
          this.nextLoading = false;
          this.getRecordList(id);
        }
      });
    },
    getDailyDetail() {
      api.get("/v1/pc/sw/swDetail", this.dailyDetailQuery).then((res) => {
        if (res.code === 200) {
          this.dailyDetail = res.data.map((v) => {
            v.query = {
              page: 1,
              keyword: "",
              limit: 10,
              submitId: v.id,
            };
            v.total = 0;
            v.isOpen = false;
            return v;
          });
          this.dailyDetailTotal = res.total;
        }
      });
    },
    entrustSearch() {
      this.entrustQuery.page = 1;
      this.getEntrustList();
    },
    entrustPageChange(page) {
      this.entrustQuery.page = page;
      this.getEntrustList();
    },
    dailyCommentPageChange(page, item) {
      this.$set(item.query, "page", page);
      api.get("/v1/pc/sw/remarkOnList", item.query).then((res) => {
        if (res.code === 200) {
          this.$set(item, "dailyComment", res.data);
          this.$set(item, "total", res.total);
          this.$set(item, "isOpen", true);
          // console.log(item);
        }
      });
    },
    dailyViewPageChange(page) {
      this.dailyViewQuery.page = page;
      this.getDailyView();
    },
    getDailyList() {
      api.get("/v1/pc/sw/list", this.dailyQuery).then((res) => {
        if (res.code === 200) {
          this.dailyList = res.data;
          this.dailyTotal = res.total;
        }
      });
    },
    getDailyView() {
      api.get("/v1/pc/sw/viewList", this.dailyViewQuery).then((res) => {
        if (res.code === 200) {
          this.dailyViewList = res.data;
          this.dailyViewTotal = res.total;
        }
      });
    },
    getDailyRecordList() {
      api.get("/v1/pc/sw/recordList", this.dailyRecordQuery).then((res) => {
        if (res.code === 200) {
          this.dailyRecordList = res.data;
          this.dailyRecordTotal = res.total;
        }
      });
    },
    checkEvaluate() {
      this.evaluateListQuery.companyId = this.companyId;
      this.getEvaluateTable();
    },
    getEvaluateTable() {
      api
        .get("/v1/pc/evaluation/evaluateList", this.evaluateListQuery)
        .then((response) => {
          const results = response.data;
          this.evaluateTableList = results;
          this.evaluateTotal = response.total;
          this.showEvaluateChart = false;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err,
          });
        });
    },
    // 表格单页数据数量的切换
    handleEvaluateSizeChange(val) {
      this.listQuery.count = val;
      this.getEvaluateTable();
    },
    // 表格页数的切换
    handleEvaluateCurrentChange(val) {
      this.listQuery.page = val;
      this.getEvaluateTable();
    },
    handleEvaluate() {
      api
        .get("/v1/pc/evaluation/evaluateChartData", {
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.forEach((r) => {
              r.chartData = {};
              r.chartData.columns = ["key", "value"];
              r.chartData.rows = r.data;
              delete r.data;
            });
            this.evaluateList = res.data;
            this.showEvaluate = true;
          }
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err,
          });
        });
    },
    goHACCPDetail(id) {
      this.HACCPDetailId = id;
      let data = {
        id,
        ...this.haccpQuery2,
      };

      api.get("/v1/pc/haccpScreen/haccpRecordList", data).then((res) => {
        if (res.code === 200) {
          this.showHACCPDetail = true;
          this.haccpDetailTotal = res.total;
          this.haccpDetailList = res.data;
        }
      });
      //this.showHACCPDetail = true
    },
    initMap() {
      api.get(`/v1/pc/entrust/companyList`, this.listQuery).then((response) => {
        let res = response.data;
        this.total = response.total;
        this.companyList = res;
        let companiesData = this.convertData(res);
        let arr = [];
        companiesData.forEach((item, index) => {
          arr.push({
            type: "effectScatter",
            coordinateSystem: "bmap",
            zlevel: 2,
            rippleEffect: {
              //涟漪特效
              period: 4, //动画时间，值越小速度越快
              brushType: "stroke", //波纹绘制方式 stroke, fill
              scale: 4, //波纹圆环最大限制，值越大波纹越大
            },
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#19d4ae",
              },
            },
            data: item,
          });
        });
        this.chartSeries = arr;
        this.getGeo();
      });
    },
    afterSet: function (echarts) {
      var bmap = echarts.getModel().getComponent("bmap").getBMap();
      bmap.addControl(new window.BMap.MapTypeControl());
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.initMap();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.listQuery.page = val;
      this.initMap();
    },
    restFilterForm() {
      this.filterForm = {
        flag: 0,
        goodsName: "",
        batchNumber: "",
        gtin: "",
      };
    },
    restFilterRecordForm() {
      this.filterRecordForm = {
        goodsName: "",
        gtin: "",
        batchNumber: "",
        carNumber: "",
        goodsType: 5,
      };
    },
    convertData(data) {
      let result = [];
      for (let i = 0; i < data.length; i++) {
        let formattedData = [
          [
            data[i].longitude,
            data[i].latitude,
            data[i].companyName,
            data[i].companyId,
            data[i].address,
            data[i].company_type_id,
          ],
        ];
        result.push(formattedData);
      }
      return result;
    },
    async handleRecord() {
      this.srlistPage = 1;
      this.rrlistPage = 1;
      await Promise.all([this.fetchSendRecords(), this.fetchReceiveRecords()]);
      this.recordDialogVisible = true;
    },
    async fetchSendRecords() {
      await this.$extraApi
        .get(`/v1/pc/area/receiptAndDeliveryData`, {
          companyId: this.companyId,
          ...this.filterRecordForm,
          type: 1,
          page: this.srlistPage,
        })
        .then((response) => {
          let res = response.data;
          res.forEach((item) => {
            item.hashFlag = false;
            item.hashVal = createHash(30);
          });
          this.$set(this.recordData, "send_out_records", res);
          this.$set(this.recordData, "send_out_records_total", response.total);
        });
    },
    async fetchReceiveRecords() {
      await this.$extraApi
        .get(`/v1/pc/area/receiptAndDeliveryData`, {
          companyId: this.companyId,
          ...this.filterRecordForm,
          type: 2,
          page: this.rrlistPage,
        })
        .then((response) => {
          let res = response.data;
          res.forEach((item) => {
            item.hashFlag = false;
            item.hashVal = createHash(30);
          });
          this.$set(this.recordData, "received_records", res);
          this.$set(this.recordData, "received_records_total", response.total);
        });
    },
    handleCurrentChangeSr(val) {
      this.srlistPage = val;
      this.fetchSendRecords();
    },
    handleCurrentChangeRr(val) {
      this.rrlistPage = val;
      this.fetchReceiveRecords();
    },
    handleStorage() {
      this.$extraApi
        .get(`/v1/pc/area/store`, {
          companyId: this.companyId,
          limit: 1000,
          ...this.filterForm,
        })
        .then((response) => {
          let res = response.data;
          this.storageData = res;
          this.storageDialogVisible = true;
        });
    },
    handleUnit() {
      this.$extraApi
        .get(`/v1/pc/area/supply`, { companyId: this.companyId })
        .then((response) => {
          let res = response.data;
          this.unitData = res;
          this.unitDialogVisible = true;
        });
    },
    handleSup() {
      this.level = "";
      this.reason = "";
      this.file = "";
      this.supDialogVisible = true;
    },
    handleRemove(file, fileList) {
      this.file = "";
    },
    handlePreview(file) {},
    handlePreviewImage(url) {
      this.url1 = [url];
      this.showViewer1 = true;
    },
    handlePreviewVideo(url) {
      window.open(url);
    },
    handleSuccess(file, fileList) {
      this.file = file.data.file_name;
    },
    async recordSortChange({ column, prop, order }, type) {
      this.filterRecordForm.sort_key = type;
      this.filterRecordForm.sort_status = order;
      await this.handleRecord();
    },
    handleCreateSup() {
      sup_record
        .save({
          company_id: this.companyId,
          level: this.level,
          reason: this.reason,
          file: this.file,
        })
        .then((response) => {
          this.$notify({
            title: "成功",
            message: "监管成功",
            type: "success",
          });
          this.supDialogVisible = false;
        });
    },
    sortChange({ column, prop, order }) {
      this.filterForm.sort_key = prop;
      order == "ascending"
        ? (this.filterForm.sort_status = 1)
        : (this.filterForm.sort_status = 0);
      this.handleStorage();
    },
    handleRowClick(row, column, event) {
      if (row.longitude && row.latitude) {
        this.$nextTick((_) => {
          this.companyInfo.company_name = row.companyName;
          this.companyInfo.address = row.address;
          this.companyInfo.company_type_id = row.company_type_id;
          this.companyId = row.companyId;
          let echarts = this.$refs.mapRef.echarts;
          let map = echarts.getModel().getComponent("bmap").getBMap();
          let point = new window.BMap.Point(row.longitude, row.latitude);
          let infoWindow = new window.BMap.InfoWindow(
            this.$refs.customInfowindow
          );
          // if (this.clickTimes == 0) {
          //   map.centerAndZoom(point, 14);
          // }
          map.setCenter(point);
          map.openInfoWindow(infoWindow, point);
          this.clickTimes++;
        });
      } else {
        this.$message({
          message: "缺少定位信息",
          type: "warning",
        });
      }
    },
    handleTable1() {
      this.listQuery1.company_id = this.companyId;
      this.initData1();
      this.table1DialogVisible = true;
    },
    handleTable2() {
      this.listQuery2.company_id = this.companyId;
      this.initData2();
      this.table2DialogVisible = true;
    },
    handleTable3() {
      this.listQuery5.company_id = this.companyId;
      this.listQuery5.trx_id = "";
      this.time = [];
      this.initData3();
      this.table3DialogVisible = true;
    },
    handleDailyClick(index) {
      this.entrustQuery.index = index;
      this.showNext = false;
      this.entrustDetail = null;
      this.entrustQuery.companyId = this.companyId;
      this.entrustQuery.page = 1;
      this.entrustQuery.keyword = "";
      this.getEntrustList();
      this.dailyVisible = true;
    },
    handleCam() {
      this.camListQuery.company_id = this.companyId;
      //this.camListQuery.company_id = "50111"
      this.initCam();
      this.camDialogVisible = true;
    },
    initData3() {
      if (this.time.length > 0) {
        this.listQuery5.add_time = this.time[0];
        this.listQuery5.end_time = this.time[1];
      }
      axios.get("/pc/company-flow-record/list", this.listQuery5).then((res) => {
        if (res.code == 200) {
          this.flowRecordList = res.data.company_flow_list;
          this.flowRecordListTotal = res.data.company_flow_count;
        }
      });
    },
    initCam() {
      axios.get("/pc/company-cam/list", this.camListQuery).then((res) => {
        if (res.code == 200) {
          this.camList = res.data.cam_list.map((v) => {
            return {
              ...v,
              isFeedback: false,
            };
          });
        }
      });
    },
    getGeo() {
      setTimeout((_) => {
        let pointArr = [];
        this.companyList.forEach((item) => {
          pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
        });
        let echarts = this.$refs.mapRef.echarts;
        let map = echarts.getModel().getComponent("bmap").getBMap();
        let view = map.getViewport(eval(pointArr));
        let mapZoom = view.zoom > 2 ? view.zoom - 1 : view.zoom;
        let centerPoint = view.center;
        map.centerAndZoom(centerPoint, mapZoom);
      }, 200);

      // let echarts = this.$refs.mapRef.echarts;
      // let map = echarts.getModel().getComponent("bmap").getBMap();
      // let myGeo = new window.BMap.Geocoder();
      // // 将地址解析结果显示在地图上，并调整地图视野
      // myGeo.getPoint(
      //   this.province + this.city + this.district,
      //   function (point) {
      //     if (point) {
      //       map.centerAndZoom(point, 14);
      //     }
      //   },
      //   this.city
      // );
    },
    verifyHash() {
      this.hashFlag = !this.hashFlag;
    },
    handleViewPic(row) {
      this.attachList = [];
      this.reportList = [];
      const { qualificationUrl, realPics } = row;
      if (realPics && realPics !== "null") {
        let realPicsTemp = JSON.parse(realPics);
        this.attachList = realPicsTemp.map((item) => item.url);
      }
      if (qualificationUrl && qualificationUrl !== "null") {
        let qualificationUrlTemp = JSON.parse(qualificationUrl);
        //console.log("img",qualificationUrlTemp);
        if (qualificationUrlTemp) {
          qualificationUrlTemp.forEach((item) => {
            if (item.length) {
              item.forEach((f) => {
                this.reportList.push(f.picUrl);
              });
            } else {
              this.reportList.push(item.picUrl);
            }
          });
        }
      }
      if (this.attachList.length > 0 || this.reportList.length > 0) {
        this.picDialogVisible = true;
      } else {
        this.$message({
          message: "无图片",
          type: "warning",
        });
      }
    },
    handleViewPic1(path) {
      this.url = path;
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
    handleInput(val) {
      this.listQuery.page = 1;
      this.initMap();
    },

    initData1() {
      this.listLoading1 = true;
      axios
        .get("/pc/company-planting-area/list", this.listQuery1)
        .then((response) => {
          let data = response.data.planting_list;
          data.forEach((item) => {
            if (item.img_url) {
              item.imgs = JSON.parse(item.img_url);
            }
          });
          this.list1 = data;
          this.listTotal1 = response.data.planting_count;
          this.listLoading1 = false;
        });
    },
    handleSizeChange1(val) {
      this.listQuery1.count = val;
      this.initData1();
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.listQuery1.pager_offset = String((val - 1) * 10);
      this.initData1();
    },
    initData2() {
      this.listLoading2 = true;
      axios
        .get("/pc/company-planting-category/list", this.listQuery2)
        .then((response) => {
          let data = response.data.planting_category_list;
          this.list2 = data;
          this.listTotal2 = response.data.planting_category_count;
          this.listLoading2 = false;
        });
    },
    handleSizeChange2(val) {
      this.listQuery2.count = val;
      this.initData2();
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val;
      this.listQuery2.pager_offset = String((val - 1) * 10);
      this.initData2();
    },
    handleGoods(id) {
      if (id) {
        this.listQuery3.planting_id = id;
      }
      this.plantingGoodsList = [];
      axios
        .get("/pc/company-planting-category/goodsList", this.listQuery3)
        .then((response) => {
          let data = response.data.planting_goods_list;
          data.forEach((item) => {
            item.imgs = [];
            if (item.img_list) {
              item.imgs = JSON.parse(item.img_list);
            }
          });
          this.plantingGoodsList = data;
          this.plantingGoodsListTotal = response.data.planting_goods_count;
        });
      this.goodsDialogVisible = true;
    },
    handleFarmRecord(id) {
      if (id) {
        this.listQuery4.planting_id = id;
      }
      this.farmRecordList = [];
      axios
        .get("/pc/company-planting-category/farmRecordList", this.listQuery4)
        .then((response) => {
          let data = response.data.planting_farmRecord_list;
          data.forEach((item) => {
            item.imgs = [];
            if (item.img_url) {
              item.imgs = item.img_url;
            }
          });
          this.farmRecordList = data;
          this.farmRecordListTotal = response.data.farmRecord_count;
        });
      this.farmDialogVisible = true;
    },

    handleViewPic2(imgs) {
      let url = imgs.map((item) => item.url);
      this.url1 = url;
      this.showViewer1 = true;
    },
    closeViewer1() {
      this.showViewer1 = false;
      this.url1 = [];
    },
  },
};
</script>
<style lang="scss" scoped >
.companies-map {
  position: relative;
  height: 100%;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.chain-block-box {
  position: absolute;
  top: 28px;
  left: 15px;
  z-index: 1099;
  padding: 5px 10px;
  background-color: #1a366a;
  color: #f2f6fc;
  cursor: default;
}
.hash-value {
  color: #409eff;
  cursor: pointer;
  z-index: 3;
}
.hash-result {
  color: #67c23a;
  cursor: pointer;
}
.company-list-box {
  position: absolute;
  top: 28px;
  right: 20px;
  z-index: 1099;
}
.company-list {
  background-color: aliceblue;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .company-list {
    background: hsla(0, 0%, 100%, 0.35);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}
.companies-map .company-list .box-card {
  width: 350px;
  background-color: inherit;
}
.companies-map .company-list .box-card .el-table__row {
  cursor: pointer;
}
/* .companies-map
  .company-list
  .box-card
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped.current-row
  td,
.el-table__body tr.current-row > td {
  background-color: #ffec8b;
} */
.companies-map .company-list .el-table__body tr.current-row > td {
  background-color: #ffec8b;
}

.companies-map .company-list .box-card .el-table tbody tr:hover > td {
  background-color: #409eff;
  color: white;
}
.el-image-viewer__close {
  color: white;
}
.el-image-viewer__wrapper {
  z-index: 9999999 !important;
}

.card-item {
  flex: 1;
  width: 31%;
  min-width: 31%;
  max-width: 31%;
  height: 400px;
  background: transparent;
  margin-bottom: 30px;
  border: 0.5px solid aqua;
  border-radius: 6px;
}
.card-item-title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-bottom: 1px solid gainsboro;
}

.card-item-content {
  padding: 20px 10px;
  height: 20vw;
}

/*.el-dialog__body  {*/
/*  padding: 0;*/
/*}*/
$color: #2194e0;
.button_hover {
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  color: #d3d7d4;
  border: 2px solid $color;
  font-size: 14px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}

.activated {
  color: #1d953f;
}
/deep/.el-table--scrollable-y ::-webkit-scrollbar {
  display: none;
}
.haccp-bg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.evaBg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
}
.el-card {
  background-color: transparent;
}
.button {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: #33a3dc;
  border: 2px solid $color;
  font-size: 16px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}
.hover {
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: $color;
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}

/deep/.daily-box {
  border-radius: 15px;
  overflow: hidden;
  .el-dialog__body {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #eee !important;
    padding: 10px !important;
  }
}
.jcb {
  justify-content: space-between;
}

.daily-detail::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.daily-detail::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background: rgba(255, 255, 255, 0.8);
  background: #bbb;
  transition: color 0.2s ease;
  cursor: pointer;
}

/deep/.el-card__header {
  padding: 8px 20px;
}

.mask-box {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}
.mask-container:hover .mask-box {
  display: flex;
}

.pointer {
  cursor: pointer;
}

/deep/.el-radio__input.is-disabled + span.el-radio__label {
  color: #000;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #606266 !important;
  background: none !important;
}
/deep/ .el-radio__input.is-disabled .el-radio__inner,
.el-radio__input.is-disabled.is-checked .el-radio__inner {
  border-color: #409eff !important;
}
/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
  background-color: #409eff;
}
.more-box {
  padding: 5px 10px;
  border-radius: 8px;
}
.more-box:hover {
  background-color: #ededed;
}
.more-btn {
  width: 100%;
  padding: 5px 10px;
  // border-radius: 8px;
}
.more-btn:hover {
  background-color: #ededed;
}
/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  text-align: center;
}
.video-up .avatar-uploader-icon {
  line-height: 100px;
}
.img-up .avatar-uploader-icon {
  line-height: 80px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
