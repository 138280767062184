const pickerOptions = {
  shortcuts: [
    {
      text: "最近一周",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "最近一个月",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "最近三个月",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit("pick", [start, end]);
      }
    }
  ]
};

const seriesOption = {
  min: -20,
  max: 60,
  detail: {
    formatter: "{value}"
  },
  axisLine: {
    show: true,
    lineStyle: {
      width: 10,
      shadowColor: "#fff",
      shadowBlur: 10,
      color: [[1, "#37a2da"]]
    }
  },
  axisLabel: {
    textStyle: {
      fontWeight: "bolder",
      color: "#fff",
      shadowColor: "#fff",
      shadowBlur: 10
    }
  },
  title: {
    textStyle: {
      fontWeight: "bolder",
      fontSize: 20,
      fontStyle: "italic",
      color: "#fff",
      shadowColor: "#fff",
      shadowBlur: 10
    }
  }
};

export { pickerOptions, seriesOption };
